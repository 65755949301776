import { actionsGenerator } from '../../shared/helper';
import {
  memberListActionNames,
  memberListDefaultActions,
  statusComponentDefaultActions,
  statusComponentActionNames
} from '../../components/memberList/constant';

const actions = {
  ...actionsGenerator(memberListActionNames, memberListDefaultActions),
  ...actionsGenerator(
    statusComponentActionNames,
    statusComponentDefaultActions,
  ),
  CLEAR_MEMBER_LIST_DETAILS: 'CLEAR_MEMBER_LIST_DETAILS',
  CLEAR_MEMBER_DETAILS: 'CLEAR_MEMBER_DETAILS',
  CLEAR_EDIT_MEMBER_DETAILS: 'CLEAR_EDIT_MEMBER_DETAILS',
  SET_PROFILE_OTHERS_MODAL_VISIBLE: 'SET_PROFILE_OTHERS_MODAL_VISIBLE',
  SET_SEARCH_VALUE_IN_MEMBER: 'SET_SEARCH_VALUE_IN_MEMBER',
  SET_SEARCH_CASE_NUMBER_IN_MEMBER: 'SET_SEARCH_CASE_NUMBER_IN_MEMBER',
  SET_SEARCH_NUMBER_IN_MEMBER: 'SET_SEARCH_NUMBER_IN_MEMBER',
  SET_SEARCH_SSN_IN_MEMBER: 'SET_SEARCH_SSN_IN_MEMBER',
  SET_SEARCH_DOB_IN_MEMBER: 'SET_SEARCH_DOB_IN_MEMBER',
  SET_SELECTED_STATUS_IN_MEMBER: 'SET_SELECTED_STATUS_IN_MEMBER',
  SET_DISCIPLINE_STATUS_SEARCH_VALUE: 'SET_DISCIPLINE_STATUS_SEARCH_VALUE',
  SET_PROFILE_OTHERS_STATUS_EDIT_DATA: 'SET_PROFILE_OTHERS_STATUS_EDIT_DATA',
  CLEAR_STATUS_EDIT_DATA: 'CLEAR_STATUS_EDIT_DATA',
  SET_BENEFIT_DETAILS_TO_VIEW: 'SET_BENEFIT_DETAILS_TO_VIEW',
  CLEAR_SELECT_OPTIONS: 'CLEAR_SELECT_OPTIONS',
  SET_BENEFICIARIES_PERCENTAGE_FOR_VALIDATION:
    'SET_BENEFICIARIES_PERCENTAGE_FOR_VALIDATION',
  TITLE_CODE_BASED_CREATION: 'TITLE_CODE_BASED_CREATION',
  TITLE_CODE_BASED_CREATION_SUCCESS: 'TITLE_CODE_BASED_CREATION_SUCCESS',
  TITLE_CODE_BASED_CREATION_FAILURE: 'TITLE_CODE_BASED_CREATION_FAILURE',
  SET_LIFE_INSURANCE_DETAILS_TO_VIEW: 'SET_LIFE_INSURANCE_DETAILS_TO_VIEW',
  MEMBER_DETAILS_PROFILE_UPDATE_SUCCESS:
    'MEMBER_DETAILS_PROFILE_UPDATE_SUCCESS',
  SET_EMPLOYMENT_STATUS_FILTER_VALUE: 'SET_EMPLOYMENT_STATUS_FILTER_VALUE',
  GET_LODI_DENIED_REASONS: 'GET_LODI_DENIED_REASONS',
  GET_LODI_DENIED_REASONS_SUCCESS: 'GET_LODI_DENIED_REASONS_SUCCESS',
  SEND_LOGIN_INFORMATION: 'SEND_LOGIN_INFORMATION',
  SEND_LOGIN_INFORMATION_SUCCESS: 'SEND_LOGIN_INFORMATION_SUCCESS',
  SET_ANALYTICS_CONFIGURATION_DETAILS_TO_VIEW: 'SET_ANALYTICS_CONFIGURATION_DETAILS_TO_VIEW',
  GET_ANALYTIC_CONFIGURATION_DATA_IN_TABLE: 'GET_ANALYTIC_CONFIGURATION_DATA_IN_TABLE',
  GET_ANALYTIC_CONFIGURATION_DATA_IN_TABLE_SUCCESS: 'GET_ANALYTIC_CONFIGURATION_DATA_IN_TABLE_SUCCESS',
  FETCH_BENEFIT_COVERAGES_STATUSES: 'FETCH_BENEFIT_COVERAGES_STATUSES',
  FETCH_BENEFIT_COVERAGES_STATUSES_SUCCESS: 'FETCH_BENEFIT_COVERAGES_STATUSES_SUCCESS',
  FETCH_MEMBER_LIST_COUNT: 'FETCH_MEMBER_LIST_COUNT',
  FETCH_MEMBER_LIST_COUNT_SUCCESS: 'FETCH_MEMBER_LIST_COUNT_SUCCESS',
  SET_NOTES_ACTIVE: 'SET_NOTES_ACTIVE',
  FETCH_LEGISLATIVE_DETAILS: 'FETCH_LEGISLATIVE_DETAILS',
  FETCH_LEGISLATIVE_DETAILS_SUCCESS: 'FETCH_LEGISLATIVE_DETAILS_SUCCESS',
  FETCH_LEGISLATIVE_DETAILS_FAILURE: 'FETCH_LEGISLATIVE_DETAILS_FAILURE',
  ADD_NEW_BENEFIT_NOTES: 'ADD_NEW_BENEFIT_NOTES',
  FETCH_BENEFITS_TAB_NOTES: 'FETCH_BENEFITS_TAB_NOTES',
  FETCH_BENEFITS_TAB_NOTES_SUCCESS: 'FETCH_BENEFITS_TAB_NOTES_SUCCESS',
  FETCH_BENEFITS_TAB_NOTES_FAILURE: 'FETCH_BENEFITS_TAB_NOTES_FAILURE',
  UPDATE_BENEFITS_TAB_NOTES:'UPDATE_BENEFITS_TAB_NOTES',
  UPDATE_BENEFITS_TAB_NOTES_SUCCESS: 'UPDATE_BENEFITS_TAB_NOTES_SUCCESS',
  UPDATE_BENEFITS_TAB_NOTES_FAILURE: 'UPDATE_BENEFITS_TAB_NOTES_FAILURE',
  ADD_BENEFITS_TAB_NOTES:'ADD_BENEFITS_TAB_NOTES',
  ADD_BENEFITS_TAB_NOTES_SUCCESS: 'ADD_BENEFITS_TAB_NOTES_SUCCESS',
  ADD_BENEFITS_TAB_NOTES_FAILURE: 'ADD_BENEFITS_TAB_NOTES_FAILURE',
  FETCH_BENEFITS_SINGLE_TAB_NOTES: 'FETCH_BENEFITS_SINGLE_TAB_NOTES',
  FETCH_BENEFITS_SINGLE_TAB_NOTES_SUCCESS: 'FETCH_BENEFITS_SINGLE_TAB_NOTES_SUCCESS',
  FETCH_BENEFITS_SINGLE_TAB_NOTES_FAILURE: 'FETCH_BENEFITS_SINGLE_TAB_NOTES_FAILURE',
  ADD_BENEFITS_TAB_SINGLE_NOTES:'ADD_BENEFITS_TAB_SINGLE_NOTES',
  UPDATE_BENEFITS_TAB_SINGLE_NOTES:'UPDATE_BENEFITS_TAB_SINGLE_NOTES',
  UPDATE_BENEFITS_TAB_SINGLE_NOTES_SUCCESS:'UPDATE_BENEFITS_TAB_SINGLE_NOTES_SUCCESS',
  UPDATE_BENEFITS_TAB_SINGLE_NOTES_FAILURE:'UPDATE_BENEFITS_TAB_SINGLE_NOTES_FAILURE',
  GRIEVANCE_AUTO_GENERATION: 'GRIEVANCE_AUTO_GENERATION',
  GRIEVANCE_AUTO_GENERATION_SUCCESS: 'GRIEVANCE_AUTO_GENERATION_SUCCESS',
  GRIEVANCE_AUTO_GENERATION_FAILURE: 'GRIEVANCE_AUTO_GENERATION_FAILURE',
  SET_ANALYTICS_FORM_VALUE: 'SET_ANALYTICS_FORM_VALUE',
  EDIT_ANALYTICS_FORM_VALUE : 'EDIT_ANALYTICS_FORM_VALUE',
  ADD_NEW_ASSAULT_LIST: 'ADD_NEW_ASSAULT_LIST',
  DELETE_EMPLOYESS_CHARGE: 'DELETE_EMPLOYESS_CHARGE',
  DELETE_EMPLOYESS_CHARGE_SUCESS: 'DELETE_EMPLOYESS_CHARGE_SUCCESS',
  DELETE_EMPLOYESS_CHARGE_FAILURE: 'DELETE_EMPLOYESS_CHARGE_FAILURE',
  SET_RANK_FILTER_VALUE: 'SET_RANK_FILTER_VALUE',
  SET_POSITION_FILTER_VALUE: 'SET_POSITION_FILTER_VALUE'
};
export default actions;
