export const formItemLayoutReports = {
  labelCol: {
    lg: { span: 5 },
    md: { span: 24 },
    sm: { span: 24 },
    xs: { span: 24 }
  },
  wrapperCol: {
    lg: { span: 19 },
    md: { span: 24 },
    sm: { span: 24 },
    xs: { span: 24 }
  },
  labelAlign: 'left'
};

export const customCheckBoxFormItemLayout = {
  labelCol: {
    lg: { span: 5 },
    md: { span: 8 },
    xs: { span: 10 }
  },
  wrapperCol: {
    lg: { span: 19 },
    md: { span: 16 },
    xs: { span: 14 }
  },
  labelAlign: 'left'
};

export const formFirstColLayout = {
  labelCol: {
    lg: { span: 10 },
    md: { span: 24 },
    sm: { span: 24 },
    xs: { span: 24 }
  },
  wrapperCol: {
    lg: { span: 14 },
    md: { span: 22 },
    sm: { span: 24 },
    xs: { span: 24 }
  },
  layout: 'horizontal'
};

export const formSecondColLayout = {
  labelCol: {
    lg: { span: 8, offset: 1 },
    md: { span: 24 }
  },
  wrapperCol: {
    lg: { span: 15 },
    md: { span: 24, offset: 0 }
  },
  layout: 'horizontal'
};

export const footerLayout = {
  labelCol: {
    lg: { span: 24 }
  },
  wrapperCol: {
    lg: { span: 24 }
  },
  layout: 'horizontal'
};

export const delegateOptionsList = ['Active', 'Inactive'];

export const meetingsOptionsList = ['Attended', 'Not Attended'];

export const officersListKeys = {
  employees: 'employees',
  offices: 'employeeOffices',
  ranks: 'employeeRanks',
  officer_statuses: 'employeeOfficerStatuses',
  firearm_statuses: 'firearmStatuses',
  positions: 'employeePositions',
  marital_statuses: 'maritalStatuses',
  employment_statuses: 'employeeEmploymentStatuses',
  departments: 'employeeDepartments',
  sections: 'employeeSections',
  titles: 'employeeTitles',
  units: 'units',
  genders: 'genders',
  affiliations: 'affiliation',
  tour_of_duties: 'tourOfDuty',
  platoons: 'platoons',
  responder_911: 'responder911',
  facilities: 'employeeFacilities'
};

export const benefitsListKeys = ['benefits', 'paymentTypes'];

export const reportsDisciplineListKeys = [
  'disciplines',
  'employeeDisciplineCharges',
  'employeeDisciplineStatuses'
];

export const mailingLabelSelectOptions = [
  { key: 'CustomAvery5160', value: 'Avery 5160 - 1" x 2-5/8"' },
  { key: 'CustomAvery5366', value: 'Avery 5366 - 2/3" x 3-7/16"' },
  { key: 'CustomAvery5163', value: 'Avery 5163 - 2"x4"' },
  { key: 'CustomAvery5161', value: 'Avery 5161 - 1"x4"' },
  { key: 'CustomPostCard', value: 'Postcard' },
  { key: 'CustomEnvelope10', value: 'Envelope 10' }
];

export const reportsKeyValues = {
  singleEmployee: {
    apiKey: 'single_employee',
    keyName: 'singleEmployee',
    permissionKeys: 'reportSingleMember',
    readPermissionKeys: 'readReportSingleMember',
    filterKeys: {
      columns: 'reports.columns',
      employees: 'employees'
    }
  },
  benefitCoverageExpiration: {
    apiKey: 'benefit_coverage_expiration',
    keyName: 'benefitCoverageExpiration',
    permissionKeys: 'reportBenefitCoverageExpiration',
    readPermissionKeys: 'readReportBenefitCoverageExpiration',
    filterKeys: {
      benefitsCoverageExpiration: 'benefit_coverage_expiration',
      dependentBirthFrom: 'reports.dependent_birth_from',
      dependentBirthTo: 'reports.dependent_birth_to',
      dependentExpireFrom: 'reports.dependent_expire_from',
      dependentExpireTo: 'reports.dependent_expire_to'
    }
  },
  benefits: {
    apiKey: 'benefits',
    keyName: 'benefits',
    permissionKeys: 'reportBenefits',
    readPermissionKeys: 'readReportBenefits',
    isFromSettings: 'benefits',
    filterKeys: {
      benefits: 'benefits',
      activeDate: 'reports.active_date',
      inActiveDate: 'reports.inactive_date',
      paymentTypes: 'payment_types',
      disbursementYear: 'reports.disbursement_year',
      disbursementStartDate: 'reports.payment_date_from',
      disbursementEndDate: 'reports.payment_date_to',
      showDisbursements: 'reports.show_disbursements',
      showDependents: 'reports.show_dependents',
      dobRanges: 'reports.dob_ranges',
      dependentBirthFrom: 'reports.dependent_birth_from',
      dependentBirthTo: 'reports.dependent_birth_to',
      dependentExpireFrom: 'reports.dependent_expire_from',
      dependentExpireTo: 'reports.dependent_expire_to'
    }
  },
  sickBank: {
    apiKey: 'sick_bank',
    keyName: 'sickBank',
    permissionKeys: 'reportSickBank',
    readPermissionKeys: 'readReportSickBank',
    filterKeys: {
      startedAt: 'reports.started_at',
      endedAt: 'reports.ended_at'
    }
  },
  lodi: {
    apiKey: 'lodi',
    keyName: 'lodi',
    permissionKeys: 'reportLodi',
    readPermissionKeys: 'readReportLodi',
    filterKeys: {
      lodis: 'lodis',
      startedAt: 'reports.started_at',
      endedAt: 'reports.ended_at'
    }
  },
  benefitCoverages: {
    apiKey: 'benefit_coverages',
    keyName: 'benefitCoverages',
    permissionKeys: 'reportBenefitCoverages',
    readPermissionKeys: 'readReportBenefitCoverages',
    filterKeys: {
      employees: 'employees',
      benefits: 'benefits',
      showCoverages: 'reports.show_coverages'
    }
  },
  unionMeetings: {
    apiKey: 'union_meetings',
    keyName: 'unionMeetings',
    permissionKeys: 'reportUnionMeetings',
    readPermissionKeys: 'readReportUnionMeetings',
    filterKeys: {
      meetingTypes: 'meeting_types',
      meetingFrom: 'reports.started_at',
      meetingTo: 'reports.ended_at',
      delegates: 'reports.delegates',
      meetings: 'reports.meetings'
    }
  },
  employeeDelegateAssignment: {
    apiKey: 'employee_delegate_assignment',
    keyName: 'employeeDelegateAssignment',
    permissionKeys: 'reportMemberDelegateAssignment',
    readPermissionKeys: 'readReportMemberDelegateAssignment',
    filterKeys: {
      startedAt: 'reports.started_at',
      endedAt: 'reports.ended_at',
      groupByDelegate: 'reports.group_by_delegate'
    }
  },
  disciplines: {
    apiKey: 'disciplines',
    keyName: 'disciplines',
    permissionKeys: 'reportDisciplines',
    readPermissionKeys: 'readReportDisciplines',
    isFromSettings: 'discipline_settings',
    filterKeys: {
      disciplines: 'discipline_settings',
      startedAt: 'reports.started_at',
      endedAt: 'reports.ended_at',
      olrStartedAt: 'reports.filed_olr_from_date',
      olrEndedAt: 'reports.filed_olr_to_date',
      oathStartedAt: 'reports.oath_from_date',
      oathEndedAt: 'reports.oath_to_date',
      step1StartedAt: 'reports.step_1_from_date',
      step1EndedAt: 'reports.step_1_to_date',
      step2StartedAt: 'reports.step_2_from_date',
      step2EndedAt: 'reports.step_2_to_date',
      step3StartedAt: 'reports.step_3_from_date',
      step3EndedAt: 'reports.step_3_to_date',
      arbritrationStartedAt: 'reports.arbritration_from_date',
      arbritrationEndedAt: 'reports.arbritration_to_date',
      settledSteps: 'reports.settled_steps',
      disciplineStatus: 'discipline_statuses',
      pendingSteps: 'reports.pending_steps',
      isWin: 'reports.win',
      isLoss: 'reports.loss',
      caseAndAbeyance: 'reports.case_and_abeyance',
      abandonmentHearing: 'reports.abandonment_hearing',
      wasEmployeePds: 'reports.was_employee_pds',
      taImplemented: 'reports.ta_implemented',
      disciplineCharges: 'discipline_charges',
      charge: 'reports.charge',
      danNumber: 'reports.dan_number'
    }
  },
  grievances: {
    apiKey: 'grievances',
    keyName: 'grievances',
    permissionKeys: 'reportGrievances',
    readPermissionKeys: 'readReportGrievances',
    isFromSettings: 'grievances',
    filterKeys: {
      grievances: 'grievances',
      startedAt: 'reports.started_at',
      endedAt: 'reports.ended_at',
      olrStartedAt: 'reports.filed_olr_from_date',
      olrEndedAt: 'reports.filed_olr_to_date',
      oathStartedAt: 'reports.oath_from_date',
      oathEndedAt: 'reports.oath_to_date',
      step1StartedAt: 'reports.step_1_from_date',
      step1EndedAt: 'reports.step_1_to_date',
      step2StartedAt: 'reports.step_2_from_date',
      step2EndedAt: 'reports.step_2_to_date',
      step3StartedAt: 'reports.step_3_from_date',
      step3EndedAt: 'reports.step_3_to_date',
      arbritrationStartedAt: 'reports.arbritration_from_date',
      arbritrationEndedAt: 'reports.arbritration_to_date',
      settledSteps: 'reports.settled_steps',
      pendingSteps: 'reports.pending_steps',
      isWin: 'reports.win',
      isLoss: 'reports.loss'
    }
  },
  janus: {
    apiKey: 'janus',
    keyName: 'janus',
    permissionKeys: 'reportJanus',
    readPermissionKeys: 'readReportJanus',
    filterKeys: {
      janusCard: 'employees.janus_card',
      startedAt: 'reports.started_at',
      endedAt: 'reports.ended_at'
    }
  },
  officerListFilterKeys: {
    commands: 'offices',
    employees: 'employees',
    departments: 'departments',
    sections: 'sections',
    titles: 'titles',
    titleCode: 'titles.title_code',
    employeesTitleCode: 'employees.title_code',
    employeeStatus: 'employment_statuses',
    ranks: 'ranks',
    affiliations: 'affiliations',
    tourOfDuties: 'tour_of_duties',
    units: 'units',
    officerStatuses: 'officer_statuses',
    email: 'employees.email',
    workEmail: 'employees.work_email',
    firearmStatuses: 'firearm_statuses',
    maritalStatuses: 'marital_statuses',
    positions: 'positions',
    genders: 'genders',
    socialSecurityNumber: 'employees.social_security_number',
    passNumber: 'employees.a_number',
    shieldNumber: 'employees.shield_number',
    contactPersons: 'contact_persons',
    previousShieldNumber: 'employees.previous_shield_number',
    placardNumber: 'employees.placard_number',
    birthdayFrom: 'employees.birthday_from',
    birthdayTo: 'employees.birthday_to',
    startDateFrom: 'employees.start_date_from',
    startDateTo: 'employees.start_date_to',
    homePhone: 'employees.home_phone',
    cellPhone: 'employees.cellphone',
    workPhone: 'employees.work_phone',
    city: 'employees.city',
    state: 'employees.state',
    zipCode: 'employees.zipcode',
    county: 'employees.county',
    memberSinceFromDate: 'employees.member_since_from',
    memberSinceToDate: 'employees.member_since_to',
    platoons: 'platoons',
    payrollId: 'employees.payroll_id',
    longevityDateFrom: 'employees.longevity_date_from',
    longevityDateTo: 'employees.longevity_date_to',
    leaveProgressionDateFrom: 'employees.leave_progression_date_from',
    leaveProgressionDateTo: 'employees.leave_progression_date_to',
    nccDateFrom: 'employees.ncc_date_from',
    nccDateTo: 'employees.ncc_date_to',
    tshirtSize: 'employees.t_shirt_size',
    responder911: 'employees.responder_911',
    staffMember: 'employees.staff_member',
    employmentStatusesFrom: 'reports.employment_statuses_from',
    employmentStatusesTo: 'reports.employment_statuses_to',
    congressDistrictId :'reports.congress_district_id',
    assemblyDistrictId :'reports.assembly_district_id',
    senateDistrictId :'reports.senate_district_id',
    councilDistrictId: 'reports.council_district_id',
    facilities: 'facilities',
    appDownloaded: 'employees.app_downloaded',
    employmentStatusesStartDateFrom : 'reports.employment_statuses_start_date_from',
    employmentStatusesStartDateTo: 'reports.employment_statuses_start_date_to',
    employmentStatusesEndDateFrom: 'reports.employment_statuses_end_date_from',
    employmentStatusesEndDateTo: 'reports.employment_statuses_end_date_to',
    ageFrom: 'employees.age_from',
    ageTo: 'employees.age_to'
  },
  lifeInsurances: {
    apiKey: 'life_insurances',
    keyName: 'lifeInsurances',
    permissionKeys: 'lifeInsurances',
    readPermissionKeys: 'readLifeInsurances',
    filterKeys: {
      ageCategory: 'reports.age_group_type'
    }
  },
  pacfs: {
    apiKey: 'pacfs',
    keyName: 'pacfs',
    permissionKeys: 'reportPacfs',
    readPermissionKeys: 'readReportPacfs',
    filterKeys: {
      pacfs: 'pacfs',
      startedAt: 'reports.started_at',
      endedAt: 'reports.ended_at',
      amountFrom: 'reports.amount_from',
      amountTo: 'reports.amount_to',
      showDollarAmount: 'reports.show_dollar_amount'
    }
  },
  workersComp: {
    apiKey: 'workers_comp',
    keyName: 'workersComp',
    permissionKeys: 'reportWorkersComp',
    readPermissionKeys: 'readReportWorkersComp',
    filterKeys: {
      startedAt: 'reports.started_at',
      endedAt: 'reports.ended_at'
    }
  },
  beneficiary: {
    apiKey: 'beneficiary',
    keyName: 'beneficiary',
    permissionKeys: 'reportBeneficiary',
    readPermissionKeys: 'readReportBeneficiary',
    filterKeys: {
      beneficiary : 'beneficiary'
    }
  },
  memberCount: {
    apiKey: 'member_count',
    keyName: 'memberCount',
    permissionKeys: 'writeReportMemberCount',
    readPermissionKeys: 'readReportMemberCount'
  }
};
