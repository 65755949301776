import { all, call, put, takeEvery } from 'redux-saga/effects';
import { message } from 'antd';
import actions from './actions';
import settings from '../../settings';
import {
  getPromise,
  unAuthorisedRedirection,
  showErrorMessages,
  patchPromise,
} from '../../shared/helper';
import {store} from '../store';
import memberListActions from '../memberList/actions';
import {memberListActionNames, memberListKeyValues} from '../../components/memberList/constant';

export function* fetchTableDetails(params) {
  const { keyValues } = params,
    { apiKey } = keyValues,
    searchTextValue = params?.searchText || store.getState().ChangeRequest.changeRequestsDetails.searchValue,
    searchDateFilterValue = params?.searchDate || undefined,
    searchStatusTypeValue = params?.searchStatusType || undefined;


  try {
    const url = `${settings.ROOT_URL}${apiKey}?${searchTextValue ? `search_text=${searchTextValue}&` : ''}${
        searchDateFilterValue ? `search_date_from=${searchDateFilterValue[0]}&search_date_to=${
        searchDateFilterValue[1]}&` : ''}${searchStatusTypeValue ? `status=${searchStatusTypeValue}&` : ''}page=${params.page}${
        params.perPage && params.perPage !== ''
          ? `&per_page=${params.perPage}`
          : ''
      }`,
      data = yield call(() => getPromise(url));
    yield put({
      type: actions.FETCH_CHANGE_REQUESTS_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.FETCH_CHANGE_REQUESTS_DETAILS_FAILURE,
    });
    showErrorMessages(error, 'employees');
  }
}

export function* getChangeRequestDetails(params) {
  const { keyValues } = params,
    { apiKey } = keyValues;
  try {
    const url = `${settings.ROOT_URL}${apiKey}${
        params.slug ? `/${params.slug}` : ''
      }`,
      data = yield call(() => getPromise(url));
    yield put ({
      type: memberListActions[`${memberListActionNames['memberList']}_GET_DETAILS`],
      slug: data.data.attributes.slug,
      keyValues: memberListKeyValues['memberList'],
    });
    yield put({
      type: actions.GET_CHANGE_REQUESTS_LIST_SUCCESS,
      payload: data.data,
      isView: true,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.FETCH_CHANGE_REQUESTS_DETAILS_FAILURE,
    });
    showErrorMessages(error, 'employees');
  }
}
export function* updateChangeRequest(params) {
  const { changeRequestTypeId, keyValues, payload } = params,
    { apiKey, messageKey } = keyValues;
  try {
    const url = `${settings.ROOT_URL}${apiKey}/${changeRequestTypeId}`,
      data = yield call(() => patchPromise(url, payload));
    yield put({
      type: actions.UPDATE_CHANGE_REQUESTS_DETAILS_SUCCESS,
    });
    yield put({
      type: actions['SET_CHANGE_REQUEST_DETAILS_TO_VIEW'],
      isView: true,
      editData: data.data,
      changeRequestId: data.data.id,
    });
    message.success(`${messageKey} updated successfully`);
    yield put({
      type: actions.GET_CHANGE_REQUESTS_LIST_SUCCESS,
      payload: data.data,
      isView: true,
    });
  } catch (error) {
    unAuthorisedRedirection(error);
    yield put({
      type: actions.UPDATE_CHANGE_REQUESTS_DETAILS_SUCCESS,
    });
    showErrorMessages(error, keyValues['keyName']);
    store.dispatch({
      type: actions.GET_CHANGE_REQUESTS_LIST,
      keyValues: memberListKeyValues['changeRequests'],
      page: 1,
      slug: changeRequestTypeId,
    });
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_CHANGE_REQUESTS_DETAILS, fetchTableDetails),
    takeEvery(actions.UPDATE_CHANGE_REQUESTS_DETAILS, updateChangeRequest),
    takeEvery(actions.GET_CHANGE_REQUESTS_LIST, getChangeRequestDetails),
  ]);
}