import axios from 'axios';
import settings from '../settings/index';
import React, { Fragment } from 'react';
import authActions from '../redux/auth/actions';
import { history, store } from '../redux/store';
import { defaultPermissions } from './constants';
import sharedActions from '../redux/shared/actions';
import {
  camelCase,
  cloneDeep,
  forEach,
  has,
  kebabCase,
  lowerCase,
  map,
  snakeCase,
  startCase,
  sum
} from 'lodash';
import moment from 'moment';
import {ApiDateFormat, dateFormatWitTime, timeFormat, yearRangeDateFormat} from '../components/constants';
import {Icon, message, Popover, Tooltip} from 'antd';
import {dateConverter, dateWithTimeConverter, ssnFormatter} from '../components/helper';
let pluralize = require('pluralize'),
  actionCableChannel;

export function getSubDomain() {
  return window.location.host.split('.')[0];
}

export function getPromise(url, withCredentials = settings.ALLOW_CREDENTIALS) {
  return axios
    .create({ withCredentials })
    .get(url, { headers: getHeaderValue() })
    .then(response => response.data);
}

function getHeaderValue() {
  const { isSuperAccount, allowedAccounts } = store.getState().Auth;
  if (isSuperAccount && !window.isSuperUser) {
    let domainValue = localStorage.getItem(`${getSubDomain()}Account`)
      ? localStorage.getItem(`${getSubDomain()}Account`)
      : allowedAccounts[0];
    if (domainValue) {
      settings.headers.nats_subdomain = domainValue;
    }
  } else {
    delete settings.headers['nats_subdomain'];
  }
  return settings.headers;
}

export function postPromise(
  url,
  params,
  withCredentials = settings.ALLOW_CREDENTIALS
) {
  return axios
    .create({ withCredentials })
    .post(url, params, { headers: getHeaderValue() })
    .then(response => response.data);
}

export function patchPromise(
  url,
  params,
  withCredentials = settings.ALLOW_CREDENTIALS
) {
  return axios
    .create({ withCredentials })
    .patch(url, params, { headers: getHeaderValue() })
    .then(response => response.data);
}

export function deletePromise(
  url,
  params,
  withCredentials = settings.ALLOW_CREDENTIALS
) {
  return axios
    .create({ withCredentials })
    .delete(url, { headers: getHeaderValue() })
    .then(response => response.data);
}

export function getErrors(errors, keyValues) {
  let messageType,
    cloneErrors = cloneDeep(errors);
  cloneErrors.map((data, index) => (messageType = typeof data));
  if (
    isMessageTypeString(messageType)
      ? errors.length === 1
      : Object.keys(errors[0]).length === 1
  ) {
    return isMessageTypeString(messageType)
      ? errors[0]
      : composeErrorMessage(errors, keyValues);
  } else {
    const messageValues = isMessageTypeString(messageType)
        ? errors
        : composeErrorMessage(errors, keyValues),
      listTag =
        messageValues.length === 1 ? (
          <Fragment>{messageValues[0]}</Fragment>
        ) : (
          <ul>
            {messageValues.map((data, index) => (
              <li key={index}>{data}</li>
            ))}
          </ul>
        );
    return listTag;
  }
}

export function isMessageTypeString(messageType) {
  return messageType === 'string';
}

export function updateBrowserHistory(urlParam, state = '') {
  history.push({
    pathname: `${urlParam}`,
    state
  });
}

export function unAuthorisedRedirection(error) {
  if (error.response && error.response.status === 401) {
    store.dispatch({
      type: authActions.AUTHORIZATION_FAILURE
    });
    message.error({ content: getErrors(error.response.data.errors) });
    history.push('/sign-in');
  }
}

export function setBreadcrumbDetails(title, isFromProfile) {
  store.dispatch({
    type: sharedActions.SET_BREADCRUMB_DETAILS,
    subTitle: isFromProfile ? isFromProfile : title,
    infoTitle: isFromProfile ? title : ''
  });
}

export function actionsGenerator(actionNames, defaultActions) {
  let actions = {};
  Object.keys(actionNames).forEach(actionKey => {
    let actionValue = actionNames[actionKey];
    for (let defaultAction of defaultActions) {
      actions = {
        ...actions,
        [`${actionValue}_${defaultAction}`]: `${actionValue}_${defaultAction}`
      };
    }
  });
  return actions;
}

export function dateFormatter(values) {
  for (let [key, value] of Object.entries(values)) {
    if (moment.isMoment(value)) {
      values[key] = moment(value).format(ApiDateFormat);
    }
  }
  return values;
}

export function checkIsDateField(value) {
  return [
    'birthday',
    'expires_at',
    'date',
    'awarded_on',
    'started_at',
    'ended_at',
    'date_in',
    'date_out',
    'effective_date'
  ].includes(value) || value.includes('_date');
}

export const checkIsTimeField = (value) => ['time'].includes(value) || value.includes('_time');

export const convertTimeToAMPM = (inputTime) => {
  const isValidISOString = moment(inputTime, moment.ISO_8601, true).isValid();

  if (isValidISOString) {
    // Format the ISO string as "h:mm A" (12-hour format)
    return moment(inputTime).format(timeFormat);
  } else {
    // Format the time "HH:mm:ss.SSSSSS" as "h:mm A" (12-hour format)
    return moment(inputTime, 'HH:mm:ss.SSSSSS').format(timeFormat);
  }
}

export function subscribeChannel(cable, channel, method, params) {
  actionCableChannel = cable.subscriptions.create(
    { channel, ...params },
    {
      received: data => {
        method(data);
      }
    }
  );
  return actionCableChannel;
}

export function unsubscribeChannel(cable, channel, params) {
  return actionCableChannel.unsubscribe();
}

export function setKeyValue(data) {
  return data.map(data => ({
    key: data,
    value: data
  }));
}

export function showErrorMessages(error, keyValues) {
  const { response } = error,
    { status } = response;
  if (response && status !== 401) {
    if (status === 500) {
      message.error(response.statusText);
    } else {
      message.error({
        content: getErrors(error.response.data.errors, keyValues)
      });
    }
  }
}

export function checkAccessPermission(write = null, read = null) {
  const { Auth } = store.getState(),
    { userPermissionsLists } = Auth;
  if (userPermissionsLists.length) {
    const writePermission = defaultPermissions[`${write}`],
      readPermission = defaultPermissions[`${read}`];
    if (write && read) {
      return (
        userPermissionsLists.includes(writePermission) ||
        userPermissionsLists.includes(readPermission)
      );
    } else {
      return userPermissionsLists.includes(writePermission);
    }
  } else {
    return false;
  }
}

export function checkReadOnlyPermission(write, read) {
  const { Auth } = store.getState(),
    { userPermissionsLists } = Auth;
  if(userPermissionsLists.includes(read) && !userPermissionsLists.includes(write)) {
    return true;
  } else return false
}

export function checkDynamicAccessPermissions(data) {
  const { memberDetails } = store.getState().MemberList,
    { profile } = memberDetails,
    { basicProfileDetails } = profile;
  if(data === 'employee_analytics') {
    return basicProfileDetails?.editData?.attributes?.['staff_member']
  } else return false;
}

export function checkTabsAccessPermission(tabData) {
  const { Auth } = store.getState(),
    { userPermissionsLists } = Auth;
  let allowPermission = false;
  if (userPermissionsLists.length) {
    map(tabData, data => {
      if (userPermissionsLists.includes(defaultPermissions[data])) {
        allowPermission = true;
      }
    });
  }
  return allowPermission;
}

export function getModuleDetails(module, key) {
  const { saasData } = store.getState().Auth;
  return key
    ? saasData[module][key] && saasData[module][key].key_name
    : saasData[module].key_name;
}

export function addPropertyToObject(obj, key, value, index) {
  let tempObj = {},
    iterator = 0;
  for (let prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      if (iterator === index && key && value) {
        tempObj[key] = value;
      }
      tempObj[prop] = obj[prop];
      iterator++;
    }
  }
  if (!index && key && value) {
    tempObj[key] = value;
  }
  return tempObj;
}

export function convertIntoSingular(string) {
  if(string === 'leaves'){
    return 'leave';
  }
  return string ? pluralize?.singular(string) : '';
}

export function convertIntoPlural(string) {
  return pluralize(string);
}

export function getColumns(schema, isRelationshipOption, relationshipValue, isChangeRequest, tableList, profile) {
  let columns = [];
  const { saasData } = store.getState().Auth,
    mainSchema = saasData['schema'],
    lifeInsurance = mainSchema['life_insurances'],
    insuranceType = lifeInsurance ? lifeInsurance['insurance_types'] : [];
  const hiddenKeys = [
    'key_name',
    'required_fields',
    'relationship_amount_validation',
    'relationship_value',
    'spouse_contribution',
    'spouse_contribution_value',
    'dependents_premium',
    'insurance_types',
    'insurance_amount',
    'required_tables',
    'customization',
    'required_auto_fields',
    'employee_status',
    'benefits_name',
    'marital_status_divorced',
    'step_child',
    'gender_id',
    'leave_types',
    'view_benefit_disbursements',
    'additional_coverage_option',
    'update_unexpire',
    'is_handling_name_from_json',
    'discipline_setting_id',
    'coverage_expire_age',
    'optical_coverage_expire_age',
    'dental_coverage_expire_age',
    'auto_dependent_code',
    'disability_auto_expire',
    'auto_expire_week',
    'expire_relationship_types',
    'optical_coverage_expire_age',
    'order_by_relationship',
    'beneficiary_type_values',
    'expire_all_benefits',
    'employee_status_start_date',
    'optical_coverage_expiration',
    'calendar_year_expiration',
    'add_dependent_to_all_benefits',
    'pacf_percentage_value',
    'peshes',
    'retire_benefit_coverage_expire_age',
    'disabled_child_edit',
    'custom_validations',
    'auto_dues_status',
    'minimum_one_required_fields',
    'is_benefit_coverage_value',
    'additional_field',
    'type_options',
    'student_options',
    'school_status_options',
    'benefit_customization',
    'auto_expire_benefits',
    'ignore_rank'
  ]
  if(mainSchema?.['pacfs']?.['hide_status']){
    hiddenKeys.push('pacf_name');
  }
  Object.keys(schema).forEach(data => {
    if (!hiddenKeys.includes(data)) {
      if (
        [
          'date',
          'created_at',
          'meeting_date',
          'start_date',
          'end_date',
          'test_date',
          'status_date',
          'awarded_on',
          'ended_at',
          'started_at',
          'incident_date',
          'return_date',
          'expires_at',
          'birthday',
          'filed_olr',
          'last_working_date',
          'to_date',
          'from_date',
          'last_paid_date',
          'return_to_work',
          'date_received',
          'effective_date'
        ].includes(data)
      ) {
        columns.push({
          title: schema[data],
          dataIndex: `attributes[${data}]`,
          render: value => (
            <div className={'table-column'}>
              {isChangeRequest ? dateWithTimeConverter(value) : dateConverter(value)}
            </div>
          )
        });
      } else if (['amount', 'gross_amount', 'tax_amount'].includes(data)) {
        columns.push({
          title: schema[data],
          dataIndex: `attributes[${data}]`,
          render: value => (
            <div className={'table-column'}>
              {value ? `$${Number(value).toFixed(2)}` : ''}
            </div>
          )
        });
      } else if (data === 'time') {
        columns.push({
          title: schema[data],
          dataIndex: `attributes[${data}]`,
          render: value => (
            <div className={'table-column'}>
              {  value ?  new Date(value).toLocaleTimeString('en-US',
                { hour: '2-digit', minute: '2-digit', hour12: true, timeZone: 'UTC' }) : null}
            </div>
          )
        });
      } else if (
        ['physical', 'verbal','lodi_pack', 'delegate', 'incident_report',
          'received_application', 'check_void'].includes(data)
      ) {
        columns.push({
          title: schema[data],
          dataIndex: `attributes[${data}]`,
          render: value => (
            <div className={'table-column'}>{value ? 'Yes' : 'No'}</div>
          )
        });
      } else if (data === 'file') {
        columns.push({
          title: schema[data],
          dataIndex: `attributes[${data}]`,
          render: value => (
            <div className={'uploaded-file-name table-column'}>
              <a
                rel="noopener noreferrer"
                href={`${value ? value.url : ''}`}
                target="_blank"
              >
                {value ? value.name : ''}
              </a>
            </div>
          )
        });
      } else if (data === 'files') {
        columns.push({
          title: schema[data],
          dataIndex: `attributes[${data}]`,
          render: value => (
            <div className={'table-column'}>
              {value?.length
                ? value.map((data, index) => (
                  <div key={`file${index}`}
                    className={'uploaded-file-name'}>
                    <a
                      rel="noopener noreferrer"
                      href={data.url}
                      target="_blank"
                    >
                      {data.name}
                    </a>
                    <br />
                  </div>
                ))
                : ''}
            </div>
          )
        });
      } else if (data === 'attended') {
        columns.push({
          title: schema[data],
          dataIndex: `attributes[${data}]`,
          render: value => (
            <div className={'table-column'}>{value ? 'Yes' : 'No'}</div>
          )
        });
      } else if (data === 'percentage') {
        columns.push({
          title: schema[data],
          dataIndex: `attributes[${data}]`,
          render: value => <div className={'table-column'}>
            {value ? `${ typeof value === 'string' ?
              value === 'MD' ? 'MD' : JSON.parse(value).toFixed(2) 
              : value.toFixed(2) } ${value === 'MD' ? '' : '%'}` : ''}
          </div>
        });
      } else if (data === 'social_security_number') {
        columns.push({
          title: splitWords('employees.social_security_number'),
          dataIndex: `attributes[${data}]`,
          render: value => (
            <div className={'table-column'}>
              {mainSchema['employees']['social_security_number_format'] === '9'
                ? value ? ssnFormatter(value)?.numValue : '-'
                : `### - ## - ${value.substr(value.length - 4)}`}
            </div>
          )
        });
      } else if (['notes', 'description', 'address', 'fax'].includes(data)) {
        columns.push({
          title: schema[data],
          dataIndex: `attributes[${data}]`,
          render: value => <div className={'table-column'}>{value}</div>
        });
      } else if (['insurance_type'].includes(data)) {
        columns.push({
          title: schema[data],
          dataIndex: `attributes[${data}]`,
          render: value =>
            <div className={'table-column'}>
              {insuranceType?.map((item) =>
                (item.key === value ? item.value : '')
              )}
            </div>
        });
      } else if (['relationship'].includes(data)) {
        columns.push({
          title: schema[data],
          dataIndex: `attributes[${data}]`,
          render: value =>
            <div className={'table-column'}>
              {isRelationshipOption ? relationshipValue.map((item) =>
                (item.key === value ? item.value : '')
              ) : value }
            </div>
        });
      } else if (data === 'is_settled') {
        columns.push({
          title: schema[data],
          dataIndex: `attributes[${data}]`,
          render: value => (
            <div className={'table-column'}>
              {value ? getIcon() : ''}
            </div>
          )
        });
      } else if (data === 'request_type') {
        columns.push({
          title: schema[data],
          dataIndex: `attributes[${data}]`,
          render: value => (
            <div className={'table-column'}>
              {
                ['employee', 'contact'].includes(value) ?
                  saasData['ui']['change_requests'][value] :
                  ![
                    'employee_employment_status',
                    'employee_officer_status',
                    'employee_office',
                    'employee_position',
                    'employee_rank',
                    'employee_benefit',
                    'employee_section',
                    'employee_department',
                    'employee_meeting_type',
                    'employee_grievance',
                    'employee_pacf',
                    'employee_discipline_setting'
                  ].includes(value) ? convertIntoSingular(startCase(value)) : convertIntoSingular(mainSchema[
                      convertIntoPlural(
                        value.split(
                          'employee_'
                        )[1]
                      )
                    ]['key_name']
                    )
              }
            </div>
          )
        });
      } else if (data === 'status') {
        columns.push({
          title: schema[data],
          dataIndex: `attributes[${data}]`,
          render: value => <div className={'table-column'}>{startCase(value)}</div>
        });
      } else if (data === 'office_id') {
        columns.push({
          title: schema[data],
          dataIndex: `attributes[${data}]`,
          render: (text, record) =>
            <div className={'table-column'}>
              {record?.attributes?.office_name}
            </div>
        });
      } else if (data === 'dependent') {
        columns.push({
          title: schema[data],
          dataIndex: `attributes[${data}]`,
          render: value => (
            <div className={'table-column'}>
              {(value || value === 0) && value < 10 ? `0${value}` : value}
            </div>
          )
        });
      } else if (data === 'year_range') {
        columns.push({
          title: schema[data],
          dataIndex: `attributes[${data}]`,
          render: (text, record) => (
            <div className={'table-column'}>
              {record?.attributes ? `${moment(record?.attributes?.duration_from).format(yearRangeDateFormat)} - ${
                moment(record?.attributes?.duration_to).format(yearRangeDateFormat)}` : '-'}
            </div>
          )
        });
      } else if (data === 'days_balanced') {
        columns.push({
          title: schema[data],
          dataIndex: `attributes[${data}]`,
          render: (text, record) => <div className={'table-column'}>
            {record?.attributes ? record?.attributes?.days_earned - record?.attributes?.days_used < 0 ? '0' :
              record?.attributes?.days_earned - record?.attributes?.days_used : '-'}</div>
        });
      } else if(data === 'benefit_coverage_id') {
        if(schema?.['optical_coverage_expiration']) {
          if(toLowerCase(tableList?.editData?.attributes?.name) === 'optical' ||
              toLowerCase(tableList?.editData?.attributes?.name) === 'hearing aide') {
            columns.push({
              title: schema[data],
              dataIndex: `attributes[${data}]`,
              render: (text, record) =>
                <div className={'table-column'}>
                  {(record?.attributes?.relationship === 'self' &&
                    record?.type === 'benefit_disbursement') ?
                    profile?.basicProfileDetails?.editData?.attributes?.name
                    : record?.attributes?.benefit_coverage_name}
                </div>
            });
          }
        } else {
          columns.push({
            title: schema[data],
            dataIndex: `attributes[${data}]`,
            render: (text, record) =>
              <div className={'table-column'}>
                {record?.attributes?.benefit_coverage_name}
              </div>
          });
        }
      } else if(data === 'serviced_expiration') {
        if(mainSchema?.benefit_disbursements?.optical_coverage_expiration) {
          columns.push({
            title: schema[data],
            dataIndex: `attributes[${data}]`,
            render: value => <div className={'table-column'}>{dateConverter(value)}</div>
          });
        }
      } else if(data === 'payment_type_id') {
        columns.push({
          title: schema[data],
          dataIndex: 'attributes[\'payment_type_name\']',
          render: value => <div className={'table-column'}>{value}</div>
        });
      } else if(data === 'student') {
        columns.push({
          title: schema[data],
          dataIndex: `attributes[${data}]`,
          render: value => <div className={'table-column'}>{!!value ? 'Yes' : 'No'}</div>
        });
      } else {
        columns.push({
          title: schema[data],
          dataIndex: `attributes[${data}]`,
          render: value => <div className={'table-column'}>{value}</div>
        });
      }
    }
  });
  return columns;
}

export function getMemberListColumns(saasData, type = 'employees') {
  const { ui, schema } = saasData,
    title = schema[type];
  let columns = [];
  Object.values(ui[type]['table_headers']).forEach(data => {
    if (
      [
        'birthday',
        'start_date',
        'prom_prov',
        'prom_perm',
        'member_since'
      ].includes(data)
    ) {
      columns.push({
        title: title[data],
        dataIndex: `attributes[${data}]`,
        render: value => (
          <div className={'table-column'}>{dateConverter(value)}</div>
        )
      });
    } else if (
      ['do_not_mail', 'veteran_status', 'responder_911'].includes(data)
    ) {
      columns.push({
        title: title[data],
        dataIndex: `attributes[${data}]`,
        render: value => (
          <div className={'table-column'}>{value ? 'Yes' : 'No'}</div>
        )
      });
    } else if (data === 'officer_status_name') {
      columns.push({
        title: schema['officer_statuses']['key_name'],
        dataIndex: `attributes[${data}]`,
        render: value => <div className={'table-column'}>{value}</div>
      });
    } else if (data === 'departments') {
      columns.push({
        title: schema[data]['key_name'],
        dataIndex:  'attributes[department_name]',
        render: value => <div className={'table-column'}>{value}</div>
      });
    } else if (data === 'title_name') {
      columns.push({
        title: convertIntoSingular(schema['titles']['key_name']),
        dataIndex: `attributes[${data}]`,
        render: value => <div className={'table-column'}>{value}</div>
      });
    } else if (data === 'employment_status_name') {
      columns.push({
        title: schema['employment_statuses']['key_name'],
        dataIndex: `attributes[${data}]`,
        render: value => <div className={'table-column'}>{value}</div>
      });
    } else if (data === 'rank_name') {
      columns.push({
        title: convertIntoSingular(schema['ranks']['key_name']),
        dataIndex: `attributes[${data}]`,
        render: value => <div className={'table-column'}>{value}</div>
      });
    } else if (data === 'office_name') {
      columns.push({
        title: convertIntoSingular(schema['offices']['key_name']),
        dataIndex: `attributes[${data}]`,
        render: value => <div className={'table-column'}>{value}</div>
      });
    } else if (data === 'address') {
      columns.push({
        title: title[data],
        dataIndex: `attributes[${data}]`,
        render: value => <div className={'table-column'}>{value}</div>
      });
    } else if (data === 'social_security_number') {
      if(schema['employees']?.['visible_social_security_number']) {
        columns.push({
          title: title[data],
          dataIndex: `attributes[${data}]`,
          render: value => (
            <div className={'table-column'}>
              {schema['employees']['social_security_number_format'] === '9'
                ? value ? ssnFormatter(value)?.numValue : '-'
                : `### - ## - ${value.substr(value.length - 4)}`}
            </div>
          )
        });
      } else {
        columns.push({
          title: title[data],
          dataIndex: `attributes[${data}]`,
          render: value => (
            <div className={'table-column'}>{`### - ## - ${value.substr(
              value.length - 4
            )}`}</div>
          )
        });
      }
    } else if (data === 'personal_phone') {
      columns.push({
        title: `${schema['contacts']['contact_number'][data]} 
        ${schema['contacts']['contact_number'][data].includes('Phone') ? '' : 'Phone'}`,
        dataIndex: `attributes[${data}]`,
        render: value => <div className={'table-column'}>{value}</div>
      });
    } else if (data === 'personal_email') {
      columns.push({
        title: `${schema['contacts']['email_address'][data]} Email`,
        dataIndex: `attributes[${data}]`,
        render: value => <div className={'table-column'}>{value}</div>
      });
    } else if (data === 'units') {
      columns.push({
        title: `${schema['units']['key_name']}`,
        dataIndex: `attributes[${data}]`,
        render: (text, record) => <div className={'table-column'}>{record?.attributes?.unit_name}</div>
      });
    } else if(data === 'placard_number' && schema['employees']['placard_multiple']) {
      columns.push({
        title: title[data],
        dataIndex: `attributes[${data}]`,
        render: value =>
          <div className={'table-column'}>
            {value?.split(',').filter((val) => val !== '').length > 0 ? value : ''}
          </div>
      });
    } else if(data === 'placard_number' && !schema['employees']?.['placard_multiple']) {
      columns.push({
        title: title[data],
        dataIndex: `attributes[${data}]`,
        render: value => <div className={'table-column'}>{value}</div>
      });
    } else if(data === 'section_name'){
      columns.push({
        title: schema['sections']['key_name'],
        dataIndex: `attributes[${data}]`,
        render: value => <div className={'table-column'}>{value}</div>
      });
    } else if(data === 'position_name'){
      columns.push({
        title: schema['positions']['key_name'],
        dataIndex: `attributes[${data}]`,
        render: value => <div className={'table-column'}>{value}</div>
      });
    }
    else if(data === 'rank_name'){
      columns.push({
        title: schema['ranks']['key_name'],
        dataIndex: `attributes[${data}]`,
        render: value => <div className={'table-column'}>{value}</div>
      });
    }
    else if(title?.[data] || schema?.[data]?.['key_name']){
      columns.push({
        title: title?.[data] || schema?.[data]?.['key_name'],
        dataIndex: `attributes[${data}]`,
        render: value => <div className={'table-column'}>{value}</div>
      });
    }
  });
  return columns;
}

export function toCamelCase(value) {
  return camelCase(value);
}

export function toSnakeCase(value) {
  return snakeCase(value);
}

export function splitWords(key, isPlural) {
  const { saasData } = store.getState().Auth,
    splittedWords = key.split('.'),
    fromDates = [
      'birthday_from',
      'member_since_from',
      'start_date_from',
      'longevity_date_from',
      'leave_progression_date_from',
      'ncc_date_from',
      'age_from'
    ],
    toDates = [
      'birthday_to',
      'member_since_to',
      'start_date_to',
      'longevity_date_to',
      'leave_progression_date_to',
      'ncc_date_to',
      'age_to'
    ];
  if (splittedWords.length === 2) {
    let isFrom = false,
      isTo = false,
      value;
    fromDates.forEach((data, index) => {
      if (data === splittedWords[1]) {
        isFrom = data.split('_from')[0];
      }
    });
    toDates.forEach((data, index) => {
      if (data === splittedWords[1]) {
        isTo = data.split('_to')[0];
      }
    });
    value =
      saasData['schema'][splittedWords[0]][
        isFrom || isTo ? isFrom || isTo : splittedWords[1]
      ];
    return isFrom || isTo
      ? isFrom
        ? `${value} From`
        : `${value} To`
      : getWords(value, splittedWords[1], isPlural);
  } else {
    return getWords(
      saasData?.['schema']?.[splittedWords[0]]?.['key_name'],
      splittedWords?.[0],
      isPlural
    );
  }
}

export function getWords(value, pluralizeWord, isPlural) {
  return ['columns', 'show_disbursements', 'delegates', 'meetings', 'dob_ranges', 'staff_member'].includes(
    pluralizeWord
  )
    ? value
    : isPlural
      ? value
      : convertIntoSingular(value);
}

export function getErrorMessage(value) {
  return `${convertIntoSingular(value)} is required!`;
}
export function getLimitExceededErrorMessage(value) {
  return `${convertIntoSingular(value)} Limit is Exceeded!`;
}
export function getYears() {
  const { saasData } = store.getState().Auth,
    { schema } = saasData;
  let max = new Date().getFullYear(),
    date = new Date().getDate(),
    month = new Date().getMonth(),
    min = 1950,
    years = [];
  for (let i = max; i >= min; i--) {
    years.push({ key: i, value: i });
  }
  if(schema?.['benefit_disbursements']?.['is_new_year_addition'] && date >= 16 && month + 1 === 12) {
    years.unshift({ key: max + 1, value: max + 1 })
    return years
  } else {
    return years;
  }
}

export function toLowerCase(value) {
  return lowerCase(value);
}

export function getIcon() {
  return <Icon type={'check'}
    className={'tick-mark-icon'} />;
}

export function getKeyName(key) {
  const splittedWords = key.split('.');
  return splittedWords.length === 1 ? splittedWords[0] : splittedWords[1];
}

export function composeErrorMessage(errors, keyName) {
  const { saasData } = store.getState().Auth,
    { schema } = saasData;
  keyName = toSnakeCase(keyName);
  let generatedErrors = [];
  Object.keys(errors[0]).map((data, index) => {
    if (data.includes('_id') && has(errors[0], keySplit(data))) {
      return delete errors[0][keySplit(data)];
    } else {
      return false;
    }
  });
  Object.keys(errors[0]).map((data, index) =>
    errors[0][data].map((innerData, innerIndex) =>
      generatedErrors.push(`${convertIntoSingular(
        keyName && schema[keyName][data]
          ? schema[keyName][data]
          : schema[generateKeyName(data)]
            ? schema[generateKeyName(data)]['key_name']
            : startCase(convertIntoSingular(generateKeyName(data)))
      )}
       ${innerData}`)
    )
  );
  return generatedErrors;
}

export function keySplit(data) {
  let splittedWords = data.split('_id') || data.split('employee_');
  return splittedWords[0].includes('employee_')
    ? data.split('employee_')[1]
    : splittedWords[0];
}

export function generateKeyName(value) {
  const splittedKeyName = keySplit(value);
  return pluralize.plural(splittedKeyName);
}

export function convertIntoHypenValues(data) {
  return kebabCase(data);
}

export function getTotalHoursUsed(data) {
  const totalHoursUsed = sum(data.map(data => data.attributes.hours_used));
  return totalHoursUsed ? totalHoursUsed : 0;
}

export function checkBeneficiariesPercentage(event) {
  const { benefits } = store.getState().MemberList.memberDetails,
    { primaryTotalPercent, secondaryTotalPercent, contingentTotalPercent } = benefits;
  if (
    (primaryTotalPercent > 0 && primaryTotalPercent < 99.99) ||
    (secondaryTotalPercent > 0 && secondaryTotalPercent < 99.99) ||
    (contingentTotalPercent > 0 && contingentTotalPercent < 99.99)
  ) {
    const title = getModuleDetails('schema', 'beneficiaries'),
      typeLabel = splitWords('beneficiaries.beneficiary_type');
    event && event.preventDefault();
    if (
      primaryTotalPercent > 0 &&
      primaryTotalPercent < 99.99 &&
      secondaryTotalPercent > 0 &&
      secondaryTotalPercent < 99.99
    ) {
      alert(`${title} ${typeLabel} primary and secondary should be 100%`);
    } else if (
      primaryTotalPercent > 0 &&
      primaryTotalPercent < 99.99 &&
      contingentTotalPercent > 0 &&
      contingentTotalPercent < 99.99
    ) {
      alert(`${title} ${typeLabel} primary and contingent should be 100%`);
    } else if (primaryTotalPercent > 0 && primaryTotalPercent < 99.99) {
      alert(`${title} ${typeLabel} primary should be 100%`);
    } else if (secondaryTotalPercent > 0 && secondaryTotalPercent < 99.99) {
      alert(`${title} ${typeLabel} secondary should be 100%`);
    } else if (contingentTotalPercent > 0 && contingentTotalPercent < 99.99) {
      alert(`${title} ${typeLabel} Contingent should be 100%`);
    }
    return true;
  }
  return false;
}

export function setTableColumnWidth(keyName) {
  const tableContainer = document.getElementsByClassName(keyName)[0];
  const tbody = tableContainer.getElementsByTagName('tbody')[0];
  const thead = tableContainer.getElementsByTagName('thead')[0];
  if (tbody && tbody.children[0] && tbody.children[0].children) {
    const cellWidths = map(
      tbody.children[0].children,
      value => value.clientWidth
    );
    forEach(thead.children[0].children, (value, key) => {
      const cell = value.firstElementChild;
      cell.style.width = `${cellWidths[key] - 16}px`;
    });
  }
}

export function filterObjectKeys(originalObject, allowedkeys) {
  let value = [];
  if(checkAccessPermission('writeChangeRequest')) {
    return Object.keys(originalObject)
      .filter(key => allowedkeys.includes(key))
      .reduce((obj, key) => {
        obj[key] = originalObject[key];
        return obj;
      },{})
  } else {
    value = allowedkeys.filter(item => item !== 'action')
    return Object.keys(originalObject)
      .filter(key => value.includes(key))
      .reduce((obj, key) => {
        obj[key] = originalObject[key];
        return obj;
      }, {});
  }
}

export function getAgeFromBirthDay(value) {
  return value ? moment().diff(value, 'years') : '-';
}

export function getArrayIntoObject(value) {
  const newArray = [
    value?.map(item => ({
      'value': `$${item}`,
      'key': `${item}`
    }))
  ];
  return newArray[0];
}
export function getDayString(value, string) {
  return value > 1 ? `${value} ${string}s` : `${value} ${string}`;
}

export function getTaCalculation(value) {
  const start = moment(),
    end = moment(value);

  let years = end.diff(start, 'year');
  start.add(years, 'years');

  let days = moment(moment(value).format(ApiDateFormat)).diff(moment(start).format(ApiDateFormat), 'days');
  // eslint-disable-next-line no-nested-ternary
  years = years >= 0 && days >= 0 ? `In ${years}` : years < 0 ? -years : years;
  days = days < 0 ? -days : days;

  return (years === 0 && days === 0) ? 'Today' : `${getDayString(years, 'year')} ${getDayString(days, 'day')}`;
}

export function convertArrayIntoObject(value) {
  const newArray = [
    value?.map((item) => ({
      'value': item,
      'key': item
    }))
  ];
  return newArray[0];
}

export function getNotesUpdateDetails(value, label = 'Notes', isMultipleNotes, AdditionalNotes) {
  if(value?.length) {
    if(AdditionalNotes) {
      return (
        <div className={'notes-modified-data'}>
          {value.map((item, index) => (
            <div className={'poly-notes'}>
              {`${label} updated by ${item.name} on ${moment(item.timestamp).format(dateFormatWitTime)}`}{' '}
              {isMultipleNotes && (
                <Popover
                  content={<div style={{maxWidth: '600px'}}>{item.updated_text}</div>}
                  placement={'right'}
                >
                  - See {label}
                </Popover>
              )}{' '}
            </div>
          ))}{' '}
        </div>)
    } else {
      return (
        <div className={'notes-modified-data'}>
          {value.map((item, index) => index < 3 && (
            <div key={`notes-${index}`}>
              {`${label} updated by ${item.name} on ${moment(item.timestamp).format(dateFormatWitTime)}`}{' '}
              {isMultipleNotes && (
                <Tooltip className={'popover'} title={item.updated_text}>
                  - See {label}
                </Tooltip>
              )}{' '}
              {index === 2 && value.length > 3 && (
                <Popover
                  content={
                    <div>
                      {value.map((item, index) => index > 2 && (
                        <div className={'notes-popover-item'}>
                          {`${label} updated by ${item.name} on ${moment(item.timestamp).format(dateFormatWitTime)}`}{' - '}
                          <Tooltip className={'popover'} title={item.updated_text}>
                            See {label}.
                          </Tooltip>
                        </div>
                      ))}
                    </div>
                  }
                  placement={'topLeft'}
                  overlayClassName={'notes-popover-container'}
                  getPopupContainer={trigger => trigger.parentElement}
                  trigger={'click'}
                  className={'popover see-more'}
                >
                  & See more
                </Popover>
              )}
            </div>
          ))}{' '}
        </div>
      );
    }
  } else return '';
}

export function placardCustomizeYear(count) {
  if(moment() >= moment(`04-01-${moment().format('YYYY')}`)) {
    if(count === 0) {
      return moment().add(1, 'years').format('YYYY');
    } else return moment().subtract(count - 1, 'years').format('YYYY');
  } else {
    return moment().subtract(count, 'years').format('YYYY');
  }
}

export function getViewChangeRequestLabel(key, requestType) {
  const { saasData } = store.getState().Auth,
    { schema } = saasData;
  if(requestType === 'employee' && schema['employees']?.[key]) {
    return schema['employees'][key];
  } else return startCase(key);
}

export function getFullValue(value) {
  return value < 0 ? -value : value;
}

export function getWeekDaysCalculation(fromDate, toDate) {
  let weeks = null, days = null;
  if(fromDate && toDate) {
    weeks =  moment(fromDate).diff(moment(toDate), 'weeks');
    days =  moment(fromDate).diff(moment(toDate), 'days')
  } else if(fromDate && !toDate) {
    weeks =  moment(fromDate).diff(moment(), 'weeks');
    days =  moment(fromDate).diff(moment(), 'days');
  }
  weeks = getFullValue(weeks);
  days = getFullValue(days);
  return (weeks || days) ? `${weeks ? `${weeks} Week${weeks > 1 ? '(s)' : ''} ` : ''}${
    days ? `${days % 7} Day${days > 1 ? '(s)' : ''}` : ''}` : '-';
}

export function getPrescription(data) {
  const padLeadingZeros = (num, size) => {
    let format = `${num}`;
    while (format.length < size) format = `0${  format}`;
    return format;
  }
  if(data) {
    return data ? padLeadingZeros((data / 12.5).toFixed(0), 9) : 0;
  }
}

export function legislativeDetailsConvertToObject(keyValue,data) {
  if(!data || !keyValue){
    return [];
  }
  const uniqueValues = new Set();
  return data?.reduce((acc, item) => {
    const value = item?.attributes?.[keyValue];
    if (!uniqueValues.has(value) && !!value && item?.id) {
      uniqueValues.add(value);
      acc.push({key: item.id, value});
    }
    return acc;
  }, []);
}
export const unicodeToGsm = {
  0x000A: [0x0A],
  0x000C: [0x1B, 0x0A],
  0x000D: [0x0D],
  0x0020: [0x20],
  0x0021: [0x21],
  0x0022: [0x22],
  0x0023: [0x23],
  0x0024: [0x02],
  0x0025: [0x25],
  0x0026: [0x26],
  0x0027: [0x27],
  0x0028: [0x28],
  0x0029: [0x29],
  0x002A: [0x2A],
  0x002B: [0x2B],
  0x002C: [0x2C],
  0x002D: [0x2D],
  0x002E: [0x2E],
  0x002F: [0x2F],
  0x0030: [0x30],
  0x0031: [0x31],
  0x0032: [0x32],
  0x0033: [0x33],
  0x0034: [0x34],
  0x0035: [0x35],
  0x0036: [0x36],
  0x0037: [0x37],
  0x0038: [0x38],
  0x0039: [0x39],
  0x003A: [0x3A],
  0x003B: [0x3B],
  0x003C: [0x3C],
  0x003D: [0x3D],
  0x003E: [0x3E],
  0x003F: [0x3F],
  0x0040: [0x00],
  0x0041: [0x41],
  0x0042: [0x42],
  0x0043: [0x43],
  0x0044: [0x44],
  0x0045: [0x45],
  0x0046: [0x46],
  0x0047: [0x47],
  0x0048: [0x48],
  0x0049: [0x49],
  0x004A: [0x4A],
  0x004B: [0x4B],
  0x004C: [0x4C],
  0x004D: [0x4D],
  0x004E: [0x4E],
  0x004F: [0x4F],
  0x0050: [0x50],
  0x0051: [0x51],
  0x0052: [0x52],
  0x0053: [0x53],
  0x0054: [0x54],
  0x0055: [0x55],
  0x0056: [0x56],
  0x0057: [0x57],
  0x0058: [0x58],
  0x0059: [0x59],
  0x005A: [0x5A],
  0x005B: [0x1B, 0x3C],
  0x005C: [0x1B, 0x2F],
  0x005D: [0x1B, 0x3E],
  0x005E: [0x1B, 0x14],
  0x005F: [0x11],
  0x0061: [0x61],
  0x0062: [0x62],
  0x0063: [0x63],
  0x0064: [0x64],
  0x0065: [0x65],
  0x0066: [0x66],
  0x0067: [0x67],
  0x0068: [0x68],
  0x0069: [0x69],
  0x006A: [0x6A],
  0x006B: [0x6B],
  0x006C: [0x6C],
  0x006D: [0x6D],
  0x006E: [0x6E],
  0x006F: [0x6F],
  0x0070: [0x70],
  0x0071: [0x71],
  0x0072: [0x72],
  0x0073: [0x73],
  0x0074: [0x74],
  0x0075: [0x75],
  0x0076: [0x76],
  0x0077: [0x77],
  0x0078: [0x78],
  0x0079: [0x79],
  0x007A: [0x7A],
  0x007B: [0x1B, 0x28],
  0x007C: [0x1B, 0x40],
  0x007D: [0x1B, 0x29],
  0x007E: [0x1B, 0x3D],
  0x00A1: [0x40],
  0x00A3: [0x01],
  0x00A4: [0x24],
  0x00A5: [0x03],
  0x00A7: [0x5F],
  0x00BF: [0x60],
  0x00C4: [0x5B],
  0x00C5: [0x0E],
  0x00C6: [0x1C],
  0x00C9: [0x1F],
  0x00D1: [0x5D],
  0x00D6: [0x5C],
  0x00D8: [0x0B],
  0x00DC: [0x5E],
  0x00DF: [0x1E],
  0x00E0: [0x7F],
  0x00E4: [0x7B],
  0x00E5: [0x0F],
  0x00E6: [0x1D],
  0x00C7: [0x09],
  0x00E8: [0x04],
  0x00E9: [0x05],
  0x00EC: [0x07],
  0x00F1: [0x7D],
  0x00F2: [0x08],
  0x00F6: [0x7C],
  0x00F8: [0x0C],
  0x00F9: [0x06],
  0x00FC: [0x7E],
  0x0393: [0x13],
  0x0394: [0x10],
  0x0398: [0x19],
  0x039B: [0x14],
  0x039E: [0x1A],
  0x03A0: [0x16],
  0x03A3: [0x18],
  0x03A6: [0x12],
  0x03A8: [0x17],
  0x03A9: [0x15],
  0x20AC: [0x1B, 0x65]
};
export const util = {

  map: function (sub, func) {
    return [].map.apply(sub, [func])
  },
  concatMap: function (sub, func) {
    return [].concat.apply([], util.map(sub, func));
  },
  id: function (x) {
    return x;
  },
  isHighSurrogate: function (c) {
    var codeUnit = (c.charCodeAt !== undefined) ? c.charCodeAt(0) : c;
    return codeUnit >= 0xD800 && codeUnit <= 0xDBFF;
  },
  numberToHexString: function (num) {
    let number = num.toString(16);
    if (number.length === 1) {
      number = `0${  number}`;
    }
    return `0x${  number}`;
  },

  /**
   take a string and return a list of the unicode characters
   */
  unicodeCharacters: function (string) {
    var chars = util.map(string, util.id);
    var result = [];
    while (chars.length > 0) {
      if (util.isHighSurrogate(chars[0])) {
        result.push(chars.shift() + chars.shift())
      } else {
        result.push(chars.shift());
      }
    }
    return result;
  },
  /**
   take a string and return a list of the unicode codepoints
   */
  unicodeCodePoints: function (string) {
    var charCodes = util.map(string, function (x) {
      return x.charCodeAt(0);
    });
    var result = [];
    while (charCodes.length > 0) {
      if (util.isHighSurrogate(charCodes[0])) {
        var high = charCodes.shift();
        var low = charCodes.shift();
        result.push(((high - 0xD800) * 0x400) + (low - 0xDC00) + 0x10000)
      } else {
        result.push(charCodes.shift());
      }
    }
    return result;
  },
  /**
   Encode a single (unicode) character into UTF16 "bytes"
   A single unicode character may be 2 javascript characters
   */
  encodeCharUtf16: function (char) {
    return util.concatMap(char, function (c) {
      return [((0xff00 & c.charCodeAt(0)) >> 8), 0x00ff & c.charCodeAt(0)];
    });
  },
  /**
   Encode a single character into GSM0338 "bytes"
   */
  encodeCharGsm: function (char) {
    return unicodeToGsm[char.charCodeAt(0)];
  },

  _encodeEachWith: function (doEncode) {
    return function (s) {
      return util.map(util.unicodeCharacters(s), doEncode);
    }
  },
  pickencoding: function (s) {
    // choose gsm if possible otherwise unicode
    if (util.unicodeCodePoints(s).every(function (x) {
      return x in unicodeToGsm
    })) {
      return 'gsm';
    } else {
      return 'unicode';
    }
  },

  _segmentWith: function (maxSingleSegmentSize, maxConcatSegmentSize, doEncode) {
    return function (listOfUnichrs) {
      var bytes = util.map(listOfUnichrs, doEncode);

      if (listOfUnichrs.length === 0) {
        return [];
      } else if ([].concat.apply([], bytes).length <= maxSingleSegmentSize) {
        return [{
          text: listOfUnichrs,
          bytes: bytes
        }];
      }

      var segments = []
      while (listOfUnichrs.length > 0) {
        var segment = {
          text: [],
          bytes: []
        };
        let length = 0;

        function nextChrLen() {
          return bytes[0] === undefined ? length : length + bytes[0].length;
        }

        while (listOfUnichrs.length > 0 && nextChrLen() <= maxConcatSegmentSize) {
          var c = listOfUnichrs.shift()
          var b = bytes.shift();
          segment.text.push(c);
          segment.bytes.push(b);
          if (b !== undefined) length += b.length;
        }
        segments.push(segment);
      }
      return segments;
    }
  }
}

export const unicodeToGsmMapping = [{'unicode': 'U+00AB', 'uChar': '«', 'ascii': 34, 'gsmChar': '"'}, {
  'unicode': 'U+00BB', 'uChar': '»', 'ascii': 34, 'gsmChar': '"'
}, {'unicode': 'U+201C', 'uChar': '“', 'ascii': 34, 'gsmChar': '"'}, {
  'unicode': 'U+201D', 'uChar': '”', 'ascii': 34, 'gsmChar': '"'
}, {'unicode': 'U+02BA', 'uChar': 'ʺ', 'ascii': 34, 'gsmChar': '"'}, {
  'unicode': 'U+02EE', 'uChar': 'ˮ', 'ascii': 34, 'gsmChar': '"'
}, {'unicode': 'U+201F', 'uChar': '‟', 'ascii': 34, 'gsmChar': '"'}, {
  'unicode': 'U+275D', 'uChar': '❝', 'ascii': 34, 'gsmChar': '"'
}, {'unicode': 'U+275E', 'uChar': '❞', 'ascii': 34, 'gsmChar': '"'}, {
  'unicode': 'U+301D', 'uChar': '〝', 'ascii': 34, 'gsmChar': '"'
}, {'unicode': 'U+301E', 'uChar': '〞', 'ascii': 34, 'gsmChar': '"'}, {
  'unicode': 'U+FF02', 'uChar': '＂', 'ascii': 34, 'gsmChar': '"'
}, {'unicode': 'U+2018', 'uChar': '‘', 'ascii': 39, 'gsmChar': '\''}, {
  'unicode': 'U+2019', 'uChar': '’', 'ascii': 39, 'gsmChar': '\''
}, {'unicode': 'U+02BB', 'uChar': 'ʻ', 'ascii': 39, 'gsmChar': '\''}, {
  'unicode': 'U+02C8', 'uChar': 'ˈ', 'ascii': 39, 'gsmChar': '\''
}, {'unicode': 'U+02BC', 'uChar': 'ʼ', 'ascii': 39, 'gsmChar': '\''}, {
  'unicode': 'U+02BD', 'uChar': 'ʽ', 'ascii': 39, 'gsmChar': '\''
}, {'unicode': 'U+02B9', 'uChar': 'ʹ', 'ascii': 39, 'gsmChar': '\''}, {
  'unicode': 'U+201B', 'uChar': '‛', 'ascii': 39, 'gsmChar': '\''
}, {'unicode': 'U+FF07', 'uChar': '＇', 'ascii': 39, 'gsmChar': '\''}, {
  'unicode': 'U+00B4', 'uChar': '´', 'ascii': 39, 'gsmChar': '\''
}, {'unicode': 'U+02CA', 'uChar': 'ˊ', 'ascii': 39, 'gsmChar': '\''}, {
  'unicode': 'U+0060', 'uChar': '`', 'ascii': 39, 'gsmChar': '\''
}, {'unicode': 'U+02CB', 'uChar': 'ˋ', 'ascii': 39, 'gsmChar': '\''}, {
  'unicode': 'U+275B', 'uChar': '❛', 'ascii': 39, 'gsmChar': '\''
}, {'unicode': 'U+275C', 'uChar': '❜', 'ascii': 39, 'gsmChar': '\''}, {
  'unicode': 'U+0313', 'uChar': '̓', 'ascii': 39, 'gsmChar': '\''
}, {'unicode': 'U+0314', 'uChar': '̔', 'ascii': 39, 'gsmChar': '\''}, {
  'unicode': 'U+FE10', 'uChar': '︐', 'ascii': 39, 'gsmChar': '\''
}, {'unicode': 'U+FE11', 'uChar': '︑', 'ascii': 39, 'gsmChar': '\''}, {
  'unicode': 'U+00F7', 'uChar': '÷', 'ascii': 47, 'gsmChar': '/'
}, {'unicode': 'U+00BC', 'uChar': '¼', 'ascii': '1/4', 'gsmChar': '1/4'}, {
  'unicode': 'U+00BD', 'uChar': '½', 'ascii': '1/2', 'gsmChar': '1/2'
}, {'unicode': 'U+00BE', 'uChar': '¾', 'ascii': '3/4', 'gsmChar': '3/4'}, {
  'unicode': 'U+29F8', 'uChar': '⧸', 'ascii': 47, 'gsmChar': '/'
}, {'unicode': 'U+0337', 'uChar': '̷', 'ascii': 47, 'gsmChar': '/'}, {
  'unicode': 'U+0338', 'uChar': '̸', 'ascii': 47, 'gsmChar': '/'
}, {'unicode': 'U+2044', 'uChar': '⁄', 'ascii': 47, 'gsmChar': '/'}, {
  'unicode': 'U+2215', 'uChar': '∕', 'ascii': 47, 'gsmChar': '/'
}, {'unicode': 'U+FF0F', 'uChar': '／', 'ascii': 47, 'gsmChar': '/'}, {
  'unicode': 'U+29F9', 'uChar': '⧹', 'ascii': 92, 'gsmChar': '\\'
}, {'unicode': 'U+29F5', 'uChar': '⧵', 'ascii': 92, 'gsmChar': '\\'}, {
  'unicode': 'U+FE68', 'uChar': '﹨', 'ascii': 92, 'gsmChar': '\\'
}, {'unicode': 'U+FF3C', 'uChar': '＼', 'ascii': 92, 'gsmChar': '\\'}, {
  'unicode': 'U+0332', 'uChar': '̲', 'ascii': 95, 'gsmChar': '_'
}, {'unicode': 'U+FF3F', 'uChar': '＿', 'ascii': 95, 'gsmChar': '_'}, {
  'unicode': 'U+20D2', 'uChar': '⃒', 'ascii': 124, 'gsmChar': '|'
}, {'unicode': 'U+20D3', 'uChar': '⃓', 'ascii': 124, 'gsmChar': '|'}, {
  'unicode': 'U+2223', 'uChar': '∣', 'ascii': 124, 'gsmChar': '|'
}, {'unicode': 'U+FF5C', 'uChar': '｜', 'ascii': 124, 'gsmChar': '|'}, {
  'unicode': 'U+23B8', 'uChar': '⎸', 'ascii': 124, 'gsmChar': '|'
}, {'unicode': 'U+23B9', 'uChar': '⎹', 'ascii': 124, 'gsmChar': '|'}, {
  'unicode': 'U+23D0', 'uChar': '⏐', 'ascii': 124, 'gsmChar': '|'
}, {'unicode': 'U+239C', 'uChar': '⎜', 'ascii': 124, 'gsmChar': '|'}, {
  'unicode': 'U+239F', 'uChar': '⎟', 'ascii': 124, 'gsmChar': '|'
}, {'unicode': 'U+23BC', 'uChar': '⎼', 'ascii': 45, 'gsmChar': '-'}, {
  'unicode': 'U+23BD', 'uChar': '⎽', 'ascii': 45, 'gsmChar': '-'
}, {'unicode': 'U+2015', 'uChar': '―', 'ascii': 45, 'gsmChar': '-'}, {
  'unicode': 'U+FE63', 'uChar': '﹣', 'ascii': 45, 'gsmChar': '-'
}, {'unicode': 'U+FF0D', 'uChar': '－', 'ascii': 45, 'gsmChar': '-'}, {
  'unicode': 'U+2010', 'uChar': '‐', 'ascii': 45, 'gsmChar': '-'
}, {'unicode': 'U+2043', 'uChar': '⁃', 'ascii': 45, 'gsmChar': '-'}, {
  'unicode': 'U+FE6B', 'uChar': '﹫', 'ascii': 64, 'gsmChar': '@'
}, {'unicode': 'U+FF20', 'uChar': '＠', 'ascii': 64, 'gsmChar': '@'}, {
  'unicode': 'U+FE69', 'uChar': '﹩', 'ascii': 36, 'gsmChar': '$'
}, {'unicode': 'U+FF04', 'uChar': '＄', 'ascii': 36, 'gsmChar': '$'}, {
  'unicode': 'U+01C3', 'uChar': 'ǃ', 'ascii': 33, 'gsmChar': '!'
}, {'unicode': 'U+FE15', 'uChar': '︕', 'ascii': 33, 'gsmChar': '!'}, {
  'unicode': 'U+FE57', 'uChar': '﹗', 'ascii': 33, 'gsmChar': '!'
}, {'unicode': 'U+FF01', 'uChar': '！', 'ascii': 33, 'gsmChar': '!'}, {
  'unicode': 'U+FE5F', 'uChar': '﹟', 'ascii': 35, 'gsmChar': '#'
}, {'unicode': 'U+FF03', 'uChar': '＃', 'ascii': 35, 'gsmChar': '#'}, {
  'unicode': 'U+FE6A', 'uChar': '﹪', 'ascii': 37, 'gsmChar': '%'
}, {'unicode': 'U+FF05', 'uChar': '％', 'ascii': 37, 'gsmChar': '%'}, {
  'unicode': 'U+FE60', 'uChar': '﹠', 'ascii': 38, 'gsmChar': '&'
}, {'unicode': 'U+FF06', 'uChar': '＆', 'ascii': 38, 'gsmChar': '&'}, {
  'unicode': 'U+201A', 'uChar': '‚', 'ascii': 44, 'gsmChar': ','
}, {'unicode': 'U+0326', 'uChar': '̦', 'ascii': 44, 'gsmChar': ','}, {
  'unicode': 'U+FE50', 'uChar': '﹐', 'ascii': 44, 'gsmChar': ','
}, {'unicode': 'U+FE51', 'uChar': '﹑', 'ascii': 44, 'gsmChar': ','}, {
  'unicode': 'U+FF0C', 'uChar': '，', 'ascii': 44, 'gsmChar': ','
}, {'unicode': 'U+FF64', 'uChar': '､', 'ascii': 44, 'gsmChar': ','}, {
  'unicode': 'U+2768', 'uChar': '❨', 'ascii': 40, 'gsmChar': '('
}, {'unicode': 'U+276A', 'uChar': '❪', 'ascii': 40, 'gsmChar': '('}, {
  'unicode': 'U+FE59', 'uChar': '﹙', 'ascii': 40, 'gsmChar': '('
}, {'unicode': 'U+FF08', 'uChar': '（', 'ascii': 40, 'gsmChar': '('}, {
  'unicode': 'U+27EE', 'uChar': '⟮', 'ascii': 40, 'gsmChar': '('
}, {'unicode': 'U+2985', 'uChar': '⦅', 'ascii': 40, 'gsmChar': '('}, {
  'unicode': 'U+2769', 'uChar': '❩', 'ascii': 41, 'gsmChar': ')'
}, {'unicode': 'U+276B', 'uChar': '❫', 'ascii': 41, 'gsmChar': ')'}, {
  'unicode': 'U+FE5A', 'uChar': '﹚', 'ascii': 41, 'gsmChar': ')'
}, {'unicode': 'U+FF09', 'uChar': '）', 'ascii': 41, 'gsmChar': ')'}, {
  'unicode': 'U+27EF', 'uChar': '⟯', 'ascii': 41, 'gsmChar': ')'
}, {'unicode': 'U+2986', 'uChar': '⦆', 'ascii': 41, 'gsmChar': ')'}, {
  'unicode': 'U+204E', 'uChar': '⁎', 'ascii': 42, 'gsmChar': '*'
}, {'unicode': 'U+2217', 'uChar': '∗', 'ascii': 42, 'gsmChar': '*'}, {
  'unicode': 'U+229B', 'uChar': '⊛', 'ascii': 42, 'gsmChar': '*'
}, {'unicode': 'U+2722', 'uChar': '✢', 'ascii': 42, 'gsmChar': '*'}, {
  'unicode': 'U+2723', 'uChar': '✣', 'ascii': 42, 'gsmChar': '*'
}, {'unicode': 'U+2724', 'uChar': '✤', 'ascii': 42, 'gsmChar': '*'}, {
  'unicode': 'U+2725', 'uChar': '✥', 'ascii': 42, 'gsmChar': '*'
}, {'unicode': 'U+2731', 'uChar': '✱', 'ascii': 42, 'gsmChar': '*'}, {
  'unicode': 'U+2732', 'uChar': '✲', 'ascii': 42, 'gsmChar': '*'
}, {'unicode': 'U+2733', 'uChar': '✳', 'ascii': 42, 'gsmChar': '*'}, {
  'unicode': 'U+273A', 'uChar': '✺', 'ascii': 42, 'gsmChar': '*'
}, {'unicode': 'U+273B', 'uChar': '✻', 'ascii': 42, 'gsmChar': '*'}, {
  'unicode': 'U+273C', 'uChar': '✼', 'ascii': 42, 'gsmChar': '*'
}, {'unicode': 'U+273D', 'uChar': '✽', 'ascii': 42, 'gsmChar': '*'}, {
  'unicode': 'U+2743', 'uChar': '❃', 'ascii': 42, 'gsmChar': '*'
}, {'unicode': 'U+2749', 'uChar': '❉', 'ascii': 42, 'gsmChar': '*'}, {
  'unicode': 'U+274A', 'uChar': '❊', 'ascii': 42, 'gsmChar': '*'
}, {'unicode': 'U+274B', 'uChar': '❋', 'ascii': 42, 'gsmChar': '*'}, {
  'unicode': 'U+29C6', 'uChar': '⧆', 'ascii': 42, 'gsmChar': '*'
}, {'unicode': 'U+FE61', 'uChar': '﹡', 'ascii': 42, 'gsmChar': '*'}, {
  'unicode': 'U+FF0A', 'uChar': '＊', 'ascii': 42, 'gsmChar': '*'
}, {'unicode': 'U+02D6', 'uChar': '˖', 'ascii': 43, 'gsmChar': '+'}, {
  'unicode': 'U+FE62', 'uChar': '﹢', 'ascii': 43, 'gsmChar': '+'
}, {'unicode': 'U+FF0B', 'uChar': '＋', 'ascii': 43, 'gsmChar': '+'}, {
  'unicode': 'U+3002', 'uChar': '。', 'ascii': 46, 'gsmChar': '.'
}, {'unicode': 'U+FE52', 'uChar': '﹒', 'ascii': 46, 'gsmChar': '.'}, {
  'unicode': 'U+FF0E', 'uChar': '．', 'ascii': 46, 'gsmChar': '.'
}, {'unicode': 'U+FF61', 'uChar': '｡', 'ascii': 46, 'gsmChar': '.'}, {
  'unicode': 'U+FF10', 'uChar': '０', 'ascii': 48, 'gsmChar': 0
}, {'unicode': 'U+FF11', 'uChar': '１', 'ascii': 49, 'gsmChar': 1}, {
  'unicode': 'U+FF12', 'uChar': '２', 'ascii': 50, 'gsmChar': 2
}, {'unicode': 'U+FF13', 'uChar': '３', 'ascii': 51, 'gsmChar': 3}, {
  'unicode': 'U+FF14', 'uChar': '４', 'ascii': 52, 'gsmChar': 4
}, {'unicode': 'U+FF15', 'uChar': '５', 'ascii': 53, 'gsmChar': 5}, {
  'unicode': 'U+FF16', 'uChar': '６', 'ascii': 54, 'gsmChar': 6
}, {'unicode': 'U+FF17', 'uChar': '７', 'ascii': 55, 'gsmChar': 7}, {
  'unicode': 'U+FF18', 'uChar': '８', 'ascii': 56, 'gsmChar': 8
}, {'unicode': 'U+FF19', 'uChar': '９', 'ascii': 57, 'gsmChar': 9}, {
  'unicode': 'U+02D0', 'uChar': 'ː', 'ascii': 58, 'gsmChar': ':'
}, {'unicode': 'U+02F8', 'uChar': '˸', 'ascii': 58, 'gsmChar': ':'}, {
  'unicode': 'U+2982', 'uChar': '⦂', 'ascii': 58, 'gsmChar': ':'
}, {'unicode': 'U+A789', 'uChar': '꞉', 'ascii': 58, 'gsmChar': ':'}, {
  'unicode': 'U+FE13', 'uChar': '︓', 'ascii': 58, 'gsmChar': ':'
}, {'unicode': 'U+FF1A', 'uChar': '：', 'ascii': 58, 'gsmChar': ':'}, {
  'unicode': 'U+204F', 'uChar': '⁏', 'ascii': 59, 'gsmChar': ';'
}, {'unicode': 'U+FE14', 'uChar': '︔', 'ascii': 59, 'gsmChar': ';'}, {
  'unicode': 'U+FE54', 'uChar': '﹔', 'ascii': 59, 'gsmChar': ';'
}, {'unicode': 'U+FF1B', 'uChar': '；', 'ascii': 59, 'gsmChar': ';'}, {
  'unicode': 'U+FE64', 'uChar': '﹤', 'ascii': 60, 'gsmChar': '<'
}, {'unicode': 'U+FF1C', 'uChar': '＜', 'ascii': 60, 'gsmChar': '<'}, {
  'unicode': 'U+0347', 'uChar': '͇', 'ascii': 61, 'gsmChar': '='
}, {'unicode': 'U+A78A', 'uChar': '꞊', 'ascii': 61, 'gsmChar': '='}, {
  'unicode': 'U+FE66', 'uChar': '﹦', 'ascii': 61, 'gsmChar': '='
}, {'unicode': 'U+FF1D', 'uChar': '＝', 'ascii': 61, 'gsmChar': '='}, {
  'unicode': 'U+FE65', 'uChar': '﹥', 'ascii': 62, 'gsmChar': '>'
}, {'unicode': 'U+FF1E', 'uChar': '＞', 'ascii': 62, 'gsmChar': '>'}, {
  'unicode': 'U+FE16', 'uChar': '︖', 'ascii': 63, 'gsmChar': '?'
}, {'unicode': 'U+FE56', 'uChar': '﹖', 'ascii': 63, 'gsmChar': '?'}, {
  'unicode': 'U+FF1F', 'uChar': '？', 'ascii': 63, 'gsmChar': '?'
}, {'unicode': 'U+FF21', 'uChar': 'Ａ', 'ascii': 65, 'gsmChar': 'A'}, {
  'unicode': 'U+1D00', 'uChar': 'ᴀ', 'ascii': 65, 'gsmChar': 'A'
}, {'unicode': 'U+FF22', 'uChar': 'Ｂ', 'ascii': 66, 'gsmChar': 'B'}, {
  'unicode': 'U+0299', 'uChar': 'ʙ', 'ascii': 66, 'gsmChar': 'B'
}, {'unicode': 'U+FF23', 'uChar': 'Ｃ', 'ascii': 67, 'gsmChar': 'C'}, {
  'unicode': 'U+1D04', 'uChar': 'ᴄ', 'ascii': 67, 'gsmChar': 'C'
}, {'unicode': 'U+FF24', 'uChar': 'Ｄ', 'ascii': 68, 'gsmChar': 'D'}, {
  'unicode': 'U+1D05', 'uChar': 'ᴅ', 'ascii': 68, 'gsmChar': 'D'
}, {'unicode': 'U+FF25', 'uChar': 'Ｅ', 'ascii': 69, 'gsmChar': 'E'}, {
  'unicode': 'U+1D07', 'uChar': 'ᴇ', 'ascii': 69, 'gsmChar': 'E'
}, {'unicode': 'U+FF26', 'uChar': 'Ｆ', 'ascii': 70, 'gsmChar': 'F'}, {
  'unicode': 'U+A730', 'uChar': 'ꜰ', 'ascii': 70, 'gsmChar': 'F'
}, {'unicode': 'U+FF27', 'uChar': 'Ｇ', 'ascii': 71, 'gsmChar': 'G'}, {
  'unicode': 'U+0262', 'uChar': 'ɢ', 'ascii': 71, 'gsmChar': 'G'
}, {'unicode': 'U+FF28', 'uChar': 'Ｈ', 'ascii': 72, 'gsmChar': 'H'}, {
  'unicode': 'U+029C', 'uChar': 'ʜ', 'ascii': 72, 'gsmChar': 'H'
}, {'unicode': 'U+FF29', 'uChar': 'Ｉ', 'ascii': 73, 'gsmChar': 'I'}, {
  'unicode': 'U+026A', 'uChar': 'ɪ', 'ascii': 73, 'gsmChar': 'I'
}, {'unicode': 'U+FF2A', 'uChar': 'Ｊ', 'ascii': 74, 'gsmChar': 'J'}, {
  'unicode': 'U+1D0A', 'uChar': 'ᴊ', 'ascii': 74, 'gsmChar': 'J'
}, {'unicode': 'U+FF2B', 'uChar': 'Ｋ', 'ascii': 75, 'gsmChar': 'K'}, {
  'unicode': 'U+1D0B', 'uChar': 'ᴋ', 'ascii': 75, 'gsmChar': 'K'
}, {'unicode': 'U+FF2C', 'uChar': 'Ｌ', 'ascii': 76, 'gsmChar': 'L'}, {
  'unicode': 'U+029F', 'uChar': 'ʟ', 'ascii': 76, 'gsmChar': 'L'
}, {'unicode': 'U+FF2D', 'uChar': 'Ｍ', 'ascii': 77, 'gsmChar': 'M'}, {
  'unicode': 'U+1D0D', 'uChar': 'ᴍ', 'ascii': 77, 'gsmChar': 'M'
}, {'unicode': 'U+FF2E', 'uChar': 'Ｎ', 'ascii': 78, 'gsmChar': 'N'}, {
  'unicode': 'U+0274', 'uChar': 'ɴ', 'ascii': 78, 'gsmChar': 'N'
}, {'unicode': 'U+FF2F', 'uChar': 'Ｏ', 'ascii': 79, 'gsmChar': 'O'}, {
  'unicode': 'U+1D0F', 'uChar': 'ᴏ', 'ascii': 79, 'gsmChar': 'O'
}, {'unicode': 'U+FF30', 'uChar': 'Ｐ', 'ascii': 80, 'gsmChar': 'P'}, {
  'unicode': 'U+1D18', 'uChar': 'ᴘ', 'ascii': 80, 'gsmChar': 'P'
}, {'unicode': 'U+FF31', 'uChar': 'Ｑ', 'ascii': 81, 'gsmChar': 'Q'}, {
  'unicode': 'U+FF32', 'uChar': 'Ｒ', 'ascii': 82, 'gsmChar': 'R'
}, {'unicode': 'U+0280', 'uChar': 'ʀ', 'ascii': 82, 'gsmChar': 'R'}, {
  'unicode': 'U+FF33', 'uChar': 'Ｓ', 'ascii': 83, 'gsmChar': 'S'
}, {'unicode': 'U+A731', 'uChar': 'ꜱ', 'ascii': 83, 'gsmChar': 'S'}, {
  'unicode': 'U+FF34', 'uChar': 'Ｔ', 'ascii': 84, 'gsmChar': 'T'
}, {'unicode': 'U+1D1B', 'uChar': 'ᴛ', 'ascii': 84, 'gsmChar': 'T'}, {
  'unicode': 'U+FF35', 'uChar': 'Ｕ', 'ascii': 85, 'gsmChar': 'U'
}, {'unicode': 'U+1D1C', 'uChar': 'ᴜ', 'ascii': 85, 'gsmChar': 'U'}, {
  'unicode': 'U+FF36', 'uChar': 'Ｖ', 'ascii': 86, 'gsmChar': 'V'
}, {'unicode': 'U+1D20', 'uChar': 'ᴠ', 'ascii': 86, 'gsmChar': 'V'}, {
  'unicode': 'U+FF37', 'uChar': 'Ｗ', 'ascii': 87, 'gsmChar': 'W'
}, {'unicode': 'U+1D21', 'uChar': 'ᴡ', 'ascii': 87, 'gsmChar': 'W'}, {
  'unicode': 'U+FF38', 'uChar': 'Ｘ', 'ascii': 88, 'gsmChar': 'X'
}, {'unicode': 'U+FF39', 'uChar': 'Ｙ', 'ascii': 89, 'gsmChar': 'Y'}, {
  'unicode': 'U+028F', 'uChar': 'ʏ', 'ascii': 89, 'gsmChar': 'Y'
}, {'unicode': 'U+FF3A', 'uChar': 'Ｚ', 'ascii': 90, 'gsmChar': 'Z'}, {
  'unicode': 'U+1D22', 'uChar': 'ᴢ', 'ascii': 90, 'gsmChar': 'Z'
}, {'unicode': 'U+02C6', 'uChar': 'ˆ', 'ascii': 94, 'gsmChar': '^'}, {
  'unicode': 'U+0302', 'uChar': '̂', 'ascii': 94, 'gsmChar': '^'
}, {'unicode': 'U+FF3E', 'uChar': '＾', 'ascii': 94, 'gsmChar': '^'}, {
  'unicode': 'U+1DCD', 'uChar': '᷍', 'ascii': 94, 'gsmChar': '^'
}, {'unicode': 'U+2774', 'uChar': '❴', 'ascii': 123, 'gsmChar': '{'}, {
  'unicode': 'U+FE5B', 'uChar': '﹛', 'ascii': 123, 'gsmChar': '{'
}, {'unicode': 'U+FF5B', 'uChar': '｛', 'ascii': 123, 'gsmChar': '{'}, {
  'unicode': 'U+2775', 'uChar': '❵', 'ascii': 125, 'gsmChar': '}'
}, {'unicode': 'U+FE5C', 'uChar': '﹜', 'ascii': 125, 'gsmChar': '}'}, {
  'unicode': 'U+FF5D', 'uChar': '｝', 'ascii': 125, 'gsmChar': '}'
}, {'unicode': 'U+FF3B', 'uChar': '［', 'ascii': 91, 'gsmChar': '['}, {
  'unicode': 'U+FF3D', 'uChar': '］', 'ascii': 93, 'gsmChar': ']'
}, {'unicode': 'U+02DC', 'uChar': '˜', 'ascii': 126, 'gsmChar': '~'}, {
  'unicode': 'U+02F7', 'uChar': '˷', 'ascii': 126, 'gsmChar': '~'
}, {'unicode': 'U+0303', 'uChar': '̃', 'ascii': 126, 'gsmChar': '~'}, {
  'unicode': 'U+0330', 'uChar': '̰', 'ascii': 126, 'gsmChar': '~'
}, {'unicode': 'U+0334', 'uChar': '̴', 'ascii': 126, 'gsmChar': '~'}, {
  'unicode': 'U+223C', 'uChar': '∼', 'ascii': 126, 'gsmChar': '~'
}, {'unicode': 'U+FF5E', 'uChar': '～', 'ascii': 126, 'gsmChar': '~'}, {
  'unicode': 'U+2017', 'uChar': '‗', 'ascii': 95, 'gsmChar': '_'
}, {'unicode': 'U+2014', 'uChar': '—', 'ascii': 45, 'gsmChar': '-'}, {
  'unicode': 'U+2013', 'uChar': '–', 'ascii': 45, 'gsmChar': '-'
}, {'unicode': 'U+201A', 'uChar': '‚', 'ascii': 39, 'gsmChar': '\''}, {
  'unicode': 'U+2039', 'uChar': '‹', 'ascii': 62, 'gsmChar': '>'
}, {'unicode': 'U+203A', 'uChar': '›', 'ascii': 60, 'gsmChar': '<'}, {
  'unicode': 'U+203C', 'uChar': '‼', 'ascii': '!!', 'gsmChar': '!!'
}, {'unicode': 'U+201E', 'uChar': '„', 'ascii': 34, 'gsmChar': '"'}, {
  'unicode': 'U+201D', 'uChar': '”', 'ascii': 34, 'gsmChar': '"'
}, {'unicode': 'U+201C', 'uChar': '“', 'ascii': 34, 'gsmChar': '"'}, {
  'unicode': 'U+201B', 'uChar': '‛', 'ascii': 39, 'gsmChar': '\''
}, {'unicode': 'U+20E5', 'uChar': '\\', 'ascii': 92, 'gsmChar': '\\'}]

export function setLocalData(filter, value) {
  return localStorage.setItem(filter, value);
}

export function getLocalData(filter) {
  return localStorage.getItem(filter) ? localStorage.getItem(filter) : '';
}

export function getPermittedEditableFields() {
  const { Auth } = store.getState();
  const { saasData } = Auth;
  const { schema } = saasData;

  return schema?.['employees']?.['permitted_editable_fields'];
}

export function checkProfileWritePermission(fieldName) {
  // Check if user has writeEmployee permission
  if (!checkAccessPermission('writeEmployee')) {
    return true;
  }

  const permittedEditableFields = getPermittedEditableFields();

  // If no permitted editable fields are defined, return false
  if (!permittedEditableFields) {
    return false;
  }

  const permittedEditableFieldsRightsList = Object.keys(permittedEditableFields);

  // If permitted editable fields list is empty, return false
  if (permittedEditableFieldsRightsList.length === 0) {
    return false;
  }

  const { Auth } = store.getState();
  const { userPermissionsLists } = Auth;

  // Filter user's permissions based on permitted fields
  const permittedEditableFieldsRights = userPermissionsLists?.filter(rights =>
    permittedEditableFieldsRightsList.includes(rights)
  );

  // If no matching permissions or no permissions at all, return false
  if (!permittedEditableFieldsRights || permittedEditableFieldsRights.length === 0) {
    return false;
  }

  // Check if fieldName is included in permitted editable fields
  return !permittedEditableFieldsRights.some(right =>
    permittedEditableFields[right]?.includes(fieldName)
  );
}